import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from 'dpl/common/components/Icon';

const mountedStyles = {
  height: '16px',
  width: '16px'
};

const unmountedStyles = {
  height: '0px',
  width: '0px'
};

export default class PhotoAlbumBullet extends Component {
  static propTypes = {
    additionalStyles: PropTypes.shape({
      opacity: PropTypes.number.isRequired,
      transform: PropTypes.string.isRequired,
      color: PropTypes.string
    }).isRequired,
    mounted: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['Image', 'Video'])
  };

  static defaultProps = {
    type: 'Image'
  };

  state = {
    show: CONFIG.isTest,
    style: unmountedStyles
  };

  _timerId = null;

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.mounted) {
      this.updateStyles(false);
      return;
    }

    this.setState({ show: true });
    this._timerId = window.setTimeout(() => this.updateStyles(true), 10);
  }

  handleTransitionEnd = () => {
    if (!this.props.mounted) {
      this.setState({ show: false });
    }
  };

  componentDidMount() {
    if (CONFIG.isTest) {
      this.updateStyles(true);
      return;
    }

    this._timerId = window.setTimeout(() => {
      if (this.props.mounted) {
        this.setState({ show: true });
      }

      this.updateStyles(true);
    }, 10);
  }

  componentWillUnmount() {
    window.clearTimeout(this._timerId);
  }

  updateStyles(isMounted) {
    if (isMounted) {
      return this.setState({ style: mountedStyles });
    }

    return this.setState({ style: unmountedStyles });
  }

  render() {
    const { additionalStyles, type } = this.props;
    const { show, style } = this.state;

    const styles = {
      ...style,
      ...additionalStyles,
      transition: 'all 100ms ease-in-out'
    };

    if (!show) {
      return null;
    }

    return (
      <Icon
        name={type === 'Video' ? 'fetch-play-filled' : 'fetch-circle-filled'}
        className="dib white mr2"
        onTransitionEnd={this.handleTransitionEnd}
        height={styles.height}
        width={styles.width}
        style={styles}
      />
    );
  }
}
