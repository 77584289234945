export {
  GDButtonV1,
  VARIANTS as GDButtonV1Variants
} from './GDButton/GDButtonV1';

export {
  GDButtonV2,
  VARIANTS as GDButtonV2Variants
} from './GDButton/GDButtonV2';

export { GDLinkV1, VARIANTS as GDLinkV1Variants } from './GDLink/V1';
