import classnames from 'classnames';

import { VARIANTS } from './GDButtonV1';

export function computeClass({ className, disabled, outlined, variant }) {
  const disabledClasses = 'o-40';
  const variantClasses = {
    [VARIANTS.PRIMARY]: classnames(
      'b--purple',
      outlined ? 'purple bg-white' : 'white bg-purple'
    ),
    [VARIANTS.SECONDARY]: classnames(
      'black',
      outlined ? 'bg-white' : 'b--moon-gray bg-light-gray'
    ),
    [VARIANTS.WARNING]: classnames(
      'b--red',
      outlined ? 'red bg-white' : 'white bg-red'
    )
  };

  return classnames(
    'flex items-center justify-center fw-medium ph3 pv2 br3 ba',
    variantClasses[variant],
    {
      [disabledClasses]: disabled
    },
    className
  );
}
