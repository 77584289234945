import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

export const VARIANTS = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary'
});

export function GDButtonV2({
  children,
  className,
  dataTestId,
  disabled,
  onClick,
  style,
  type,
  variant
}) {
  const computedClasses = {
    [VARIANTS.PRIMARY]: 'bg-grayish-blue',
    [VARIANTS.SECONDARY]: 'bg-black-05'
  };

  const baseClasses = classnames('fw-medium ph3 pv2 br2', className);
  const disabledClasses = 'o-40';

  return (
    <button
      className={classnames(baseClasses, computedClasses[variant], {
        [disabledClasses]: disabled
      })}
      data-test-id={dataTestId}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type={type} // eslint-disable-line react/button-has-type
    >
      {children}
    </button>
  );
}

GDButtonV2.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(Object.values(VARIANTS))
};

GDButtonV2.defaultProps = {
  children: null,
  className: '',
  dataTestId: 'gd-button-v2',
  disabled: false,
  onClick: null,
  style: {},
  type: 'button',
  variant: VARIANTS.PRIMARY
};
