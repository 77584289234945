import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { __RouterContext } from 'react-router';

import { VARIANTS } from '@core/GDButton/GDButtonV1';
import { computeClass } from '@core/GDButton/GDButtonV1/computeClass';
import { getPathWithinReactRouter } from 'dpl/components/SmartLink';

export { VARIANTS };

export function GDLinkV1({
  children,
  className,
  dataTestId,
  href,
  onClick,
  outlined,
  variant,
  ...args
}) {
  const router = useContext(__RouterContext);
  const isRouterLink = router && !/^(https?|mailto):/i.test(href);

  const Component = isRouterLink ? Link : 'a';

  return (
    <Component
      {...args}
      data-test-id={dataTestId}
      className={
        variant
          ? computeClass({
              className,
              outlined,
              variant
            })
          : className
      }
      href={isRouterLink ? null : href}
      onClick={onClick}
      to={
        isRouterLink
          ? getPathWithinReactRouter(href, router.history.location.pathname)
          : null
      }
    >
      {children}
    </Component>
  );
}

GDLinkV1.contextTypes = {
  router: PropTypes.object
};

GDLinkV1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(VARIANTS))
};

GDLinkV1.defaultProps = {
  children: null,
  className: null,
  dataTestId: 'gd-link-v1',
  onClick: null,
  outlined: false,
  variant: null
};
