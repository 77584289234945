import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PhotoAlbumBullet from 'dpl/components/PhotoAlbumBullets/PhotoAlbumBullet';

export default function PhotoAlbumBullets({
  containerClassName,
  currentImageIdx,
  imageCount,
  maxBulletsPadding,
  bulletStyle,
  mediaTypes
}) {
  return (
    <div className="PhotoAlbumBullets">
      <div
        className={classnames(
          'flex justify-center align-items-center',
          containerClassName
        )}
      >
        {Array.from(Array(imageCount)).map((_, idx) => {
          const isCurrentImage = currentImageIdx === idx;
          const idxDelta = Math.abs(currentImageIdx - idx);
          const isInWindow = idxDelta <= maxBulletsPadding;

          let scale = 0;
          let opacity = 0;
          if (isCurrentImage) {
            scale = 1;
            opacity = 1;
          } else if (idxDelta >= 2) {
            scale = 0.6;
            opacity = 0.7;
          } else if (idxDelta >= 1) {
            scale = 0.8;
            opacity = 0.7;
          }

          return (
            <PhotoAlbumBullet
              type={mediaTypes[idx]}
              key={idx}
              mounted={isInWindow}
              additionalStyles={{
                opacity,
                transform: `scale(${scale})`,
                ...bulletStyle
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

PhotoAlbumBullets.propTypes = {
  containerClassName: PropTypes.string,
  currentImageIdx: PropTypes.number.isRequired,
  imageCount: PropTypes.number.isRequired,
  maxBulletsPadding: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  bulletStyle: PropTypes.object,
  mediaTypes: PropTypes.arrayOf(PropTypes.oneOf(['Image', 'Video']))
};

PhotoAlbumBullets.defaultProps = {
  containerClassName: 'pv2',
  maxBulletsPadding: 2,
  bulletStyle: null,
  mediaTypes: []
};
