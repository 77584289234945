import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import Icon from 'dpl/common/components/Icon';

import { computeClass } from './computeClass';

export const VARIANTS = Object.freeze({
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  WARNING: 'warning'
});

function getIconComponent(name, position) {
  const iconClasses = classnames(
    { ml1: position === 'right' },
    { mr1: position === 'left' }
  );

  return <Icon className={iconClasses} key={name} name={name} />;
}

export function GDButtonV1({
  children,
  className,
  dataTestId,
  disabled,
  form,
  iconName,
  iconPosition,
  onClick,
  outlined,
  style,
  type,
  variant
}) {
  const finalChildren = [children];

  if (iconName) {
    const iconComponent = getIconComponent(iconName, iconPosition);

    if (iconPosition === 'left') {
      finalChildren.unshift(iconComponent);
    } else {
      finalChildren.push(iconComponent);
    }
  }

  return (
    <button
      className={computeClass({
        className,
        disabled,
        outlined,
        variant
      })}
      data-test-id={dataTestId}
      disabled={disabled}
      form={form}
      onClick={onClick}
      style={style}
      type={type} // eslint-disable-line react/button-has-type
    >
      {finalChildren}
    </button>
  );
}

GDButtonV1.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  form: PropTypes.string,
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf(['center', 'left', 'right']),
  onClick: PropTypes.func,
  outlined: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  variant: PropTypes.oneOf(Object.values(VARIANTS))
};

GDButtonV1.defaultProps = {
  children: null,
  className: null,
  dataTestId: 'gd-button-v1',
  disabled: false,
  form: null,
  iconName: null,
  iconPosition: 'left',
  onClick: null,
  outlined: false,
  style: {},
  type: 'button',
  variant: VARIANTS.PRIMARY
};
